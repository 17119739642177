<template>
  <div v-if="items && items.length > 0">
    <slot />
    <CommonListLabel
      v-if="label && isVertical"
      :label="label"
      :link="showHeaderLink ? `/${$t('Routes.Job')}` : undefined"
    />
    <CommonSectionLabel
      v-else-if="label"
      :title="label"
      :link="showHeaderLink ? $t('Routes.Job') : undefined"
    />
    <div ref="listEl">
      <template v-if="isSlider">
        <LayoutSwipeList
          :container="false"
          @swiper="onSwiperInit"
          @slideChange="onHorizontalChange"
        >
          <SwiperSlide v-for="(item, index) in items" :key="index">
            <JobTeaserCard :item="item" :placement="placement" />
          </SwiperSlide>
        </LayoutSwipeList>
      </template>
      <template v-else-if="isSinglePage">
        <CommonDivider :dark="false" customMargin="mt-4" />
        <JobTeaser
          v-for="item in items"
          :job="item"
          :big="true"
          :key="item.id"
          :placement="placement"
        />
      </template>
      <template v-else-if="b2c">
        <div
          class="grid md:grid-cols-2 gap-y-2 md:gap-x-8 md:gap-y-4 mb-2 md:my-10"
        >
          <JobTeaser
            v-for="(item, index) in items"
            b2c
            big
            :key="index"
            :job="item"
            :placement="placement"
            class="transition-opacity duration-200"
            :class="{ 'hidden md:block': index >= 4 }"
          />
        </div>
      </template>
      <template v-else>
        <CommonDivider :dark="false" customMargin="mt-4" />
        <LayoutPagedList
          :initial-slide="activeIndex"
          controls-margin="mt-4 md:mt-5"
          @swiper="onSwiperInit"
          @slideChange="onVerticalChange"
        >
          <SwiperSlide v-for="(page, i) in splitList" :key="`page ${i}`">
            <JobTeaser
              v-for="job in page"
              :key="job.id"
              :job="job"
              :placement="placement"
            />
          </SwiperSlide>
        </LayoutPagedList>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'
import { tagTypes } from '~/api/jobs'
import type ExtendedSwiper from '~/typesManual/swiper'

import type { JobAdListDto } from '~/typesAuto/jobshot/v1'

const nuxtApp = useNuxtApp()
const route = useRoute()
const config = useRuntimeConfig()

const dataLayer = useDataLayer()

const indexStore = useIndexStore()

const props = withDefaults(
  defineProps<{
    jobs?: JobAdListDto[]
    count?: number
    isSlider?: boolean
    isSinglePage?: boolean
    hasFixedTop?: boolean
    placementId?: string
    label?: string | false
    showHeaderLink?: boolean
    random?: boolean
    isRandomItems?: boolean
    b2c?: boolean
  }>(),
  {
    count: 5,
    isSlider: false,
    isSinglePage: false,
    hasFixedTop: true,
    label: 'Joblistings',
    showHeaderLink: true,
    random: false,
    isRandomItems: false,
    b2c: false,
  }
)

const placements = config.public.site.job?.placements

const placement = computed(() => {
  if (props.placementId) {
    return props.placementId
  }

  if (true && indexStore.currentPaperSlug) {
    return indexStore.currentPaperSlug
  }

  if (route.name === 'frontpage') {
    return placements?.frontpage ?? placements?.default
  }

  return placements?.default
})

const apiPageSize = computed(() =>
  props.isSinglePage || props.b2c ? props.count : 100
)

const asyncDataKey = `jobs-${apiPageSize.value}-${placement.value}-${props.isRandomItems}-${props.isSinglePage}-${indexStore.currentPaperIdentifier}`

const { data: apiJobs } = await useAsyncData(
  asyncDataKey,
  async () => {
    if (props.jobs) {
      return null
    }

    const response = await nuxtApp.$api.jobs.listJobAds({
      placementId: placement.value,
      pageSize: apiPageSize.value,
      random: Boolean(props.isRandomItems),
    })
    return response.data?.filter((jobAd) => jobAd.slug) // Temporary fix for missing slug
  },
  {
    // Only fetch the same data once. No need to refetch on route change or multiple times on same page.
    getCachedData: (key) => nuxtApp.payload.data[key],
    dedupe: 'defer',
  }
)

const items = computed(() => props.jobs ?? apiJobs.value)

// vertical list
const isVertical = !props.isSlider && !props.isSinglePage
const totalPages = computed(() => {
  if (isVertical && items.value) {
    return Math.ceil(items.value.length / props.count)
  }
})
const activeIndex = computed(() => {
  if (isVertical && props.random && totalPages.value) {
    return Math.floor(Math.random() * totalPages.value)
  }
})
const splitList = computed(() => {
  if (isVertical && items.value && totalPages.value) {
    const tempList = []
    const fixedItem =
      props.hasFixedTop &&
      items.value?.find((job) =>
        job.jobAdTags?.find(
          (jobAdTag) => jobAdTag.tag?.id === tagTypes.jobOfTheDay
        )
      )
    const dynamicList = fixedItem
      ? items.value.filter((job) => job.id !== fixedItem?.id)
      : items.value
    const count = fixedItem ? props.count - 1 : props.count
    for (let page = 0; page < totalPages.value; page++) {
      const offset = page * count
      const limit = offset + count
      const pageList = dynamicList.slice(offset, limit)
      fixedItem && pageList.unshift(fixedItem)
      tempList.push(pageList)
    }
    return tempList
  }
})

// impression tracking
const listEl = ref<HTMLElement | null>(null)
const isVisible = ref<boolean>(false)

const getIds = (items: JobAdListDto[] | null | undefined) =>
  items?.map((item) => item.id).filter((id): id is number => id !== undefined)

const currentIds = ref<number[] | undefined>(
  // Get initial IDs if list starts on first page
  props.isSinglePage ? getIds(items.value) : undefined
)
const previousIds = ref<number[]>([])

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        isVisible.value = entry.isIntersecting

        trackImpression()
      })
    },
    {
      threshold: 0.5,
      rootMargin: '-53px 0px 0px 0px',
    }
  )

  if (listEl.value) {
    observer.observe(listEl.value)
  }
})

const trackImpression = (idsToTrack?: number[]) => {
  if (
    isVisible.value &&
    currentIds.value &&
    currentIds.value !== previousIds.value &&
    placement.value
  ) {
    dataLayer.job.listImpression(
      idsToTrack ?? currentIds.value,
      placement.value
    )
    previousIds.value = currentIds.value
  }
}

const onSwiperInit = (swiper: ExtendedSwiper) => {
  if (props.isSlider) {
    onHorizontalChange(swiper)
  } else if (isVertical) {
    onVerticalChange(swiper)
  }
}

const onVerticalChange = (swiper: ExtendedSwiper) => {
  currentIds.value = getIds(splitList.value?.[swiper.activeIndex])
  trackImpression()
}

const onHorizontalChange = (swiper: ExtendedSwiper) => {
  currentIds.value = getIds(
    items.value?.filter((_, index) =>
      swiper.visibleSlidesIndexes?.includes(index)
    )
  )
  // filter out previously tracked ids and include them again if items have left the viewport and are visible again
  trackImpression(
    currentIds.value?.filter((id) => !previousIds.value?.includes(id))
  )
}
</script>
